import {HTTP} from '@/libs/https.js';
import config from '@/libs/config.js';

class AssetMaintenanceModel extends HTTP {
    getWarehouseName(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_WAREHOUSE_NAME,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAssetClassList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ASSET_CLASSES_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAssetMaintenanceList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ASSET_MAINTENANCE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getOperationPopupDetails(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_OPERATION_POPUP_DETAILS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    departmentalAssetFlow(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DEPARTMENTAL_ASSET_FLOW,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    exportSchoolDeptAssets(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EXPORT_SCHOOL_DEPT_ASSETS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    assetRepatriation(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ASSET_REPATRIATION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    assetRepair(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ASSET_REPAIR,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    assetRetirement(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ASSET_RETIREMENT,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    assetDetail(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ASSET_DETAIL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

}

export { AssetMaintenanceModel };
