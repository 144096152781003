import { debounce } from "common-local";

export default {
    data() {
        return {
            excessHeight: 137, // 有分页器高度
            excessHeight2: 95, // 没有分页器高度
        }
    },
    mounted() {
        this.$_resizeHandler()
        this.$_initResizeEvent()
    },
    beforeDestroy() {
        this.$_destroyResizeEvent()
    },
    activated() {
        this.$_initResizeEvent()
    },
    deactivated() {
        this.$_destroyResizeEvent()
    },
    methods: {
        $_initResizeEvent() {
            window.addEventListener('resize', this.$_resizeListener)
        },
        $_destroyResizeEvent() {
            window.removeEventListener('resize', this.$_resizeListener)
        },
        $_resizeListener:debounce(function () {
            this.$_resizeHandler()
        }, 200),
        $_resizeHandler() {
            this.$nextTick(() => {
                if (this.$refs.table) {
                    let height = document.body.clientHeight - this.$refs.table.$el.offsetTop
                    if(document.getElementsByClassName('pagination-container')[0]) {
                        height -= this.excessHeight
                    } else {
                        height -= this.excessHeight2
                    }
                    if (this.table_config) {
                        this.table_config.height = height
                    } else if (this.tableConfig) {
                        this.tableConfig.height = height
                    }
                    console.log(`[${this.$route?.meta?.title}表格高度]:`, height);
                }
            });
        },
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$_resizeHandler()
        },
    }
}
