import {HTTP} from '@/libs/https.js';
import config from '@/libs/config.js';

class AssetWarehouseAssetsModel extends HTTP {
    getDepartmentPeople(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_DEPARTMENT_PEOPLE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    //资产管理初次入库
    InitialImport(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.INITIAL_IMPORT,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export { AssetWarehouseAssetsModel };
