import { debounce } from "common-local"
import { getBrowserWheelZoomRatio } from "@/libs/utils";

export default {
    data() {
        return {
            wheelZoomEnabled: true,
            wheelZoomRatio: 100,
            wheelZoomRatioDom: null,
            wheelZoomRatioDomHover: false,
            wheelZoomRatioValueDom: null
        }
    },
    created() {
        // 自定义缩放与浏览器缩放同步
        if (this.wheelZoomEnabled) {
            let browserWheelZoomRatio = getBrowserWheelZoomRatio()
            if (browserWheelZoomRatio != 100) {
                this.wheelZoomRatio = browserWheelZoomRatio
            }
        }
    },
    mounted() {
        // this.$_addWheelZoomEventListener()
        if (this.wheelZoomRatioDom) {
            document.body.removeChild(this.wheelZoomRatioDom);
        }
    },
    beforeDestroy() {
        // this.$_removeWheelZoomEventListener()
        if (this.wheelZoomRatioDom) {
            document.body.removeChild(this.wheelZoomRatioDom);
        }
    },
    activated() {
        // this.$_addWheelZoomEventListener()
    },
    deactivated() {
        // this.$_removeWheelZoomEventListener()
    },
    methods: {
        /*注册事件*/
        $_addWheelZoomEventListener() {
            // document.addEventListener('DOMMouseScroll', this.$_handleWheelZoomEvent, false)
            // document.addEventListener('mousewheel', this.$_handleWheelZoomEvent, { passive: false })
            // document.addEventListener('wheel', this.$_handleWheelZoomEvent, { passive: false })
            document.body.addEventListener('wheel', this.$_handleWheelZoomEvent, { passive: false })
            document.body.addEventListener('keydown', this.$_handleKeydownZoomEvent)
        },
        $_removeWheelZoomEventListener() {
            // document.removeEventListener('DOMMouseScroll', this.$_handleWheelZoomEvent, false)
            // document.removeEventListener('mousewheel', this.$_handleWheelZoomEvent, { passive: false })
            // document.removeEventListener('wheel', this.$_handleWheelZoomEvent, { passive: false })
            document.body.removeEventListener('wheel', this.$_handleWheelZoomEvent, { passive: false })
            document.body.removeEventListener('keydown', this.$_handleKeydownZoomEvent)
        },
        $_handleWheelZoomEvent(e) {
            console.log("[$_handleWheelZoomEvent]e:", e)
            if (e.ctrlKey) {
                if (e.deltaY < 0) { // 向上滚动
                    // console.log("[$_handleWheelZoomEvent]e.deltaY:", e.deltaY)
                    e.preventDefault();
                    if (this.wheelZoomEnabled && this.wheelZoomRatio < 200) {
                        this.wheelZoomRatio += 25
                        this.$_wheelZoomRatioShow()
                    }
                    console.log("[$_handleWheelZoomEvent]this.wheelZoomRatio:", this.wheelZoomRatio)
                    return false;
                }
                if (e.deltaY > 0) { // 向下滚动
                    // console.log("[$_handleWheelZoomEvent]e.deltaY:", e.deltaY)
                    e.preventDefault();
                    if (this.wheelZoomEnabled && this.wheelZoomRatio > 25) {
                        this.wheelZoomRatio -= 25
                        this.$_wheelZoomRatioShow()
                    }
                    console.log("[$_handleWheelZoomEvent]this.wheelZoomRatio:", this.wheelZoomRatio)
                    return false;
                }
            }
        },
        $_handleKeydownZoomEvent(event) {
            const keyCodeMap = {
                // 91: true, // command
                107: true, // 数字键盘 +
                109: true, // 数字键盘 -
                187: true, // +
                189: true, // -
                61: true, // 火狐+号
                173: true // 火狐-号
            };
            const e = event || window.event;
            var ctrlKey = e.ctrlKey || e.metaKey;
            var keyCode = e.keyCode || e.which || e.charCode;
            console.log("[$_handleKeydownZoomEvent]keyCode", keyCode)
            if (ctrlKey && [48, 96].includes(keyCode)) { // Ctrl+0
                this.wheelZoomRatio = 100
            }
            if (ctrlKey && keyCodeMap[keyCode]) { // Ctrl
                e.preventDefault();
                if (this.wheelZoomEnabled) {
                    if ([107, 187, 61].includes(keyCode)) { // +
                        this.wheelZoomRatio += 25
                    } else if ([109, 189, 173].includes(keyCode)) { // -
                        this.wheelZoomRatio -= 25
                    }
                    this.$_wheelZoomRatioShow()
                }
                return false;
            } else if (e.detail) { // Firefox
                event.returnValue = false;
            }
        },
        $_wheelZoomRatioShow() {
            if (this.wheelZoomRatioDom) {
                this.wheelZoomRatioDom.style.display = 'inline'
                this.wheelZoomRatioValueDom.innerHTML = `${this.wheelZoomRatio}%`;
            } else {
                // var isLight = window.matchMedia('(prefers-color-scheme: light)').matches;
                // console.log('isLight', isLight);
                let isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
                // console.log('isDarkMode', isDarkMode);
                this.wheelZoomRatioDom = document.createElement('div');
                this.$_setStyle(this.wheelZoomRatioDom, {
                    position: 'absolute',
                    top: 0,
                    right: '20vw',
                    zIndex: '1999',
                    width: '200px',
                    height: '40px',
                    background: '#FFFFFF',
                    borderRadius: '5px',
                    border: '2px solid #E0E0E0',
                    padding: '0 20px',
                    userSelect: 'none',
                    '-webkit-user-select': 'none', // webkit浏览器
                    '-moz-user-select': 'none', // Firefox
                    '-ms-user-select': 'none' // IE10+
                })
                if (isDarkMode) {
                    this.$_setStyle(this.wheelZoomRatioDom, {
                        background: '#4A4A4A',
                        border: '1px solid gray',
                    })
                }
                this.wheelZoomRatioDom.innerHTML = ""
                this.wheelZoomRatioDom.onmouseover = () => {
                    this.wheelZoomRatioDomHover = true
                }
                this.wheelZoomRatioDom.onmouseout = () => {
                    this.wheelZoomRatioDomHover = false
                }
                document.body.appendChild(this.wheelZoomRatioDom);
                // 容器Dom
                let containerDom = document.createElement('div')
                this.$_setStyle(containerDom, {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                })
                this.wheelZoomRatioDom.appendChild(containerDom)
                // 缩放比率Dom
                this.wheelZoomRatioValueDom = document.createElement('span')
                this.wheelZoomRatioValueDom.innerHTML = `${this.wheelZoomRatio}%`;
                this.$_setStyle(this.wheelZoomRatioValueDom, {
                    color: '#000',
                    fontSize: "12px",
                    lineHeight: "1"
                })
                if (isDarkMode) {
                    this.$_setStyle(this.wheelZoomRatioValueDom, {
                        color: "#fff",
                    })
                }
                containerDom.appendChild(this.wheelZoomRatioValueDom)
                // 减按钮
                let subtractBtnDom = document.createElement('span')
                subtractBtnDom.innerHTML = "_"
                this.$_setStyle(subtractBtnDom, {
                    color: '#000',
                    fontSize: "28px",
                    fontWeight: '200',
                    cursor: "pointer",
                    marginTop: "-30px",
                    marginLeft: "auto"
                })
                if (isDarkMode) {
                    this.$_setStyle(subtractBtnDom, {
                        color: "#fff",
                    })
                }
                subtractBtnDom.onclick = () => {
                    this.wheelZoomRatio -= 25
                    this.wheelZoomRatioValueDom.innerHTML = `${this.wheelZoomRatio}%`;
                }
                containerDom.appendChild(subtractBtnDom)
                // 加按钮
                let addBtnDom = document.createElement('span')
                addBtnDom.innerHTML = "+"
                this.$_setStyle(addBtnDom, {
                    color: '#000',
                    fontSize: "28px",
                    fontWeight: '200',
                    lineHeight: "1",
                    cursor: "pointer",
                    marginTop: "-2px",
                    marginLeft: "16px"
                })
                if (isDarkMode) {
                    this.$_setStyle(addBtnDom, {
                        color: "#fff",
                    })
                }
                addBtnDom.onclick = () => {
                    this.wheelZoomRatio += 25
                    this.wheelZoomRatioValueDom.innerHTML = `${this.wheelZoomRatio}%`;
                }
                containerDom.appendChild(addBtnDom)
                // 重置按钮
                let resetBtnDom = document.createElement('div')
                resetBtnDom.innerHTML = "重置"
                this.$_setStyle(resetBtnDom, {
                    width: "60px",
                    height: "30px",
                    background: "#EFEFEF",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: '#000',
                    fontSize: "12px",
                    cursor: "pointer",
                    marginLeft: "12px"
                })
                if (isDarkMode) {
                    this.$_setStyle(resetBtnDom, {
                        background: "#5C5C5C",
                        color: "#fff",
                    })
                }
                resetBtnDom.onclick = () => {
                    this.wheelZoomRatio = 100
                    this.wheelZoomRatioValueDom.innerHTML = `${this.wheelZoomRatio}%`;
                }
                containerDom.appendChild(resetBtnDom)
            }
            this.$_wheelZoomRatioHide()
        },
        // 设置样式的函数
        $_setStyle(ele, styleObj) {
            for (let attr in styleObj) {
                ele.style[attr] = styleObj[attr]
            }
        },
        $_wheelZoomRatioHide: debounce(function () {
            setTimeout(() => {
                if (this.wheelZoomRatioDomHover) {
                    this.$_wheelZoomRatioHide()
                } else {
                    this.wheelZoomRatioDom.style.display = 'none'
                }
            }, 1000);
        }, 1000)
    }
}