export default {
    data () {
        return {
            template_btn_info: {
                'primary': '#409eff', //查询
                'success': '#67c23a', //编辑
                'info': '#909399', // 重置
                'warning': '#e6a23c', // 清除
                'danger': '#f56c6c', // 删除
                'submit': '#4bd863'
            },
            btnColor: '#ffffff'
        }
    },
    computed: {
    },
    methods: {
    }

}
